module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://dev-isovera-cms.pantheonsite.io/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M4CVJ7P","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 599px)","tabletPortrait":"(min-width: 600px)","tabletLandscape":"(min-width: 900px)","desktopUp":"(min-width: 1200px)","bigDesktopUp":"(min-width: 1600px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Isovera One","short_name":"Isovera One","start_url":"/","background_color":"#ff5353","theme_color":"#ff5353","display":"standalone","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"713a0ebbe68eb6164bfe47dd0eddef12"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
